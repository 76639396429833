import React from 'react';

function HeroSection() {
  return (
    <div className="hero-container" style={{ position: 'static', zIndex: '1' }}>
      <style>
        {`
          .btn-order-now {
            background-color: #6E1300;
            color: #F9F5EA;
            transition: background-color 0.3s ease;
          }

          .btn-order-now:hover {
            background-color: #A41D00;
            color: #F9F5EA;
          }

          .btn-outline-dark {
            border: 1px solid #6E1300;
            color: #6E1300;
            transition: background-color 0.3s ease;
          }

          .btn-outline-dark:hover {
            background-color: #6E1300;
            color: #F9F5EA;
          }

          /* Media query for mobile view */
          @media (max-width: 480px) {
            .button-group {
              display: flex;
              flex-direction: row;
              justify-content: center;
              gap: 1rem; /* Space between the buttons */
            }

            .button-group a {
              width: 45%; /* Adjust button width for smaller screens */
              text-align: center;
            }
          }
        `}
      </style>
      <div className="container-fluid d-flex align-items-center" style={{ height: '70vh', width: '100%' }}>
        <div className="row h-100">
          {/* Left side with the image */}
          <div className="col-md-6 d-flex justify-content-center align-items-center" style={{ backgroundColor: '#FEE6AA' }}>
            <img 
              src='https://picsum.photos/200/300' 
              alt="Candle" 
              className="img-fluid" 
              style={{ maxHeight: '80vh' }} 
            />
          </div>
          
          {/* Right side with the text */}
          <div className="col-md-6 d-flex flex-column justify-content-center" style={{ backgroundColor: '#F9F5EA' }}>
            <h1 className="display-4 fw-bold mb-4">
              Discover the Perfect Snack – <span className="text-muted">Nutritious, Delicious, NOSHT.</span>
            </h1>
            <div className="button-group">
              <a href="#contact-us" className="btn btn-order-now btn-lg rounded-pill px-5">
                Order Now
              </a>
              <a href="#contact-us" className="btn btn-outline-dark btn-lg rounded-pill px-5">
                For Retailers
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HeroSection;

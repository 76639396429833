import React from 'react';
import logo from '../assets/RGB/PNG/Pastel Yellow.png'; // Adjust the path to your logo image

function Footer() {
  const currentYear = new Date().getFullYear(); // Get the current year dynamically

  return (
    <footer style={{ backgroundColor: '#6E1300', color: '#FEE6AA', padding: '40px 0' }}>
      <div className="container">
        <div className="row">
          {/* Logo and Quick Links */}
          <div className="col-md-4">
            <img src={logo} alt="Scandleted Logo" style={{ maxWidth: '150px', marginBottom: '20px' }} />
            <ul style={{ listStyleType: 'none', paddingLeft: 0, lineHeight: '2em' }}>
              <li><a href="#" style={{ color: '#fff', textDecoration: 'none' }}>About Us</a></li>
              <li><a href="#" style={{ color: '#fff', textDecoration: 'none' }}>Vision, Mission, and Goals</a></li>
              <li><a href="#" style={{ color: '#fff', textDecoration: 'none' }}>Our Team</a></li>
              <li><a href="#" style={{ color: '#fff', textDecoration: 'none' }}>Contact Us</a></li>
            </ul>
          </div>

          {/* Legal Links */}
          <div className="col-md-4" style={{marginTop: '3.5rem'}}>
            <h4>Legal</h4>
            <ul style={{ listStyleType: 'none', paddingLeft: 0, lineHeight: '2em' }}>
              <li><a href="#" style={{ color: '#fff', textDecoration: 'none' }}>Privacy Policy</a></li>
              <li><a href="#" style={{ color: '#fff', textDecoration: 'none' }}>Terms & Conditions</a></li>
            </ul>
          </div>

          {/* Social Media Icons */}
          <div className="col-md-4" style={{marginTop: '3.5rem'}}>
            <h4>Social Media</h4>
            <ul style={{ listStyleType: 'none', paddingLeft: 0, lineHeight: '2em' }}>
              <li><a href="#" style={{ color: '#fff', textDecoration: 'none' }}>Facebook</a></li>
              <li><a href="#" style={{ color: '#fff', textDecoration: 'none' }}>Instagram</a></li>
              <li><a href="#" style={{ color: '#fff', textDecoration: 'none' }}>Twitter</a></li>
            </ul>
          </div>
        </div>

        <hr style={{ borderTop: '1px solid #444' }} />

        <div className="row">
          <div className="col-md-6">
            <p>© {currentYear}<span className='px-1'><b>NOSHT</b></span>. All rights reserved.</p>
          </div>
          <div className="col-md-6 text-end">
            <p>Developed by Vineet Shrivastava</p>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;

import React, { useState, useEffect } from "react";
import "./navbar.css";
import { Link, NavLink } from "react-router-dom";
import desktopLogo from '../assets/RGB/PNG/Pastel Yellow.png';
import mobileLogo from '../assets/RGB/PNG/Rich Brown.png'; // New mobile logo

function Header() {
  const [menuOpen, setMenuOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 480);

  // Handle resize event to switch logo based on screen width
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 480);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <nav>
      <Link to="/" className="title">
        <img
          src={isMobile ? mobileLogo : desktopLogo}
          className="navbar-brand"
          width="200"
          height="100"
          alt="Nosht"
        />
      </Link>
      <div className="menu" onClick={() => setMenuOpen(!menuOpen)}>
        <span></span>
        <span></span>
        <span></span>
      </div>
      <ul className={menuOpen ? "open" : ""}>
      <li>
          <NavLink to="/" activeClassName="active">Home</NavLink>
        </li>
        <li>
          <NavLink to="/about" activeClassName="active">About Us</NavLink>
        </li>
        <li>
          <NavLink to="/Vision Mission and Goals" activeClassName="active">
          Vision, Mission and Goals</NavLink>
        </li>
        <li>
          <NavLink to="/team" activeClassName="active">
          Our Team</NavLink>
        </li>
        <li>
          <NavLink to="/contactus" activeClassName="active">Contact</NavLink>
        </li>
      </ul>
    </nav>
  );
}

export default Header;

import React from 'react';
import coverImage from '../assets/RGB/cover1.png'; // Replace with the actual path to your image

function CoverImageSection() {
  return (
    <div className="cover-image-section" style={{ backgroundColor: '#F9F5EA', padding: '20px 0' }}>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-12">
            <img
              src={coverImage}
              alt="Cover"
              className="img-fluid"
              style={{
                width: '100%',
                maxHeight: '500px', // Adjust this value to fit your design
                objectFit: 'cover',
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default CoverImageSection;

import React from 'react'
import HeroSection from '../../components/HeroSection'
import ProductCardSection from '../../components/ProductCardSection'
import CoverImageSection from '../../components/CoverImageSection'

function HomePage() {
  return (
    <div style={{backgroundColor:"#FBF8F0"}}>
        <HeroSection/>
        <ProductCardSection/>
        <CoverImageSection/>
    </div>
  )
}

export default HomePage
import React from 'react';
import ProductCard from './ProductCard';
import cardimg1 from '../assets/RGB/img1.png'

function ProductCardSection() {
  const products = [
    {
      image: cardimg1, // Replace with actual image URLs
      title: 'Vanilla ',
      description: 'Cake Smell',
      price: 20,
      originalPrice: 24,
      discount: 5,
    },
    {
      image: cardimg1, // Replace with actual image URLs
      title: 'Almond ',
      description: 'Enjoy the afternoon',
      price: 40,
    },
    {
      image: cardimg1, // Replace with actual image URLs
      title: 'Brown ',
      description: 'Together in the afternoon',
      price: 33,
      originalPrice: 37,
      discount: 10,
    },
  ];

  return (
    <div className="container my-5"  style={{ position: 'static', zIndex: '1' }}>
      <h3 className="text-center" style={{ fontFamily: 'serif', fontWeight: 'bold', marginBottom: '40px', color:'#6E1300' }}>
        TRENDING
      </h3>
      <p className="text-center" style={{ fontFamily: 'serif', fontSize: '24px', marginBottom: '40px', color:'#6E1300'  }}>
        Shop our popular 
      </p>
      <div className="row">
        {products.map((product, index) => (
          <ProductCard key={index} {...product} />
        ))}
      </div>
    </div>
  );
}

export default ProductCardSection;

import React from 'react'
import { Routes,Route } from 'react-router-dom'
import Layout from './layouts/Layout'
import HomePage from './pages/HomePage/HomePage'
import Contactus from './pages/Contactus'
import VisionMissionGoals from './pages/VisionMissionGoals'
function App() {
  return (
   
    <Layout>
<Routes>
  <Route path='/' element={<HomePage/>} />
  <Route path='/aboutus' element={<Contactus/>} />
  <Route path='/Vision Mission and Goals' element={<VisionMissionGoals/>} />
  <Route path='/team' element={<Contactus/>} />
  <Route path='/contactus' element={<Contactus/>} />
</Routes>
    </Layout>
   
  )
}

export default App
import React, { useState } from 'react';
import axios from 'axios';
import './Contactus.css';

function Contactus() {
  const [isOrderFormVisible, setIsOrderFormVisible] = useState(true);
  const [orderFormData, setOrderFormData] = useState({
    name: '',
    email: '',
    phone: '',
    message: '',
  });
  const [retailerFormData, setRetailerFormData] = useState({
    retailer_name: '',
    contact_person: '',
    email: '',
    phone: '',
    message: '',
  });
  const [formSuccess, setFormSuccess] = useState('');
  const [formError, setFormError] = useState('');

  const handleOrderChange = (e) => {
    setOrderFormData({
      ...orderFormData,
      [e.target.name]: e.target.value,
    });
  };

  const handleRetailerChange = (e) => {
    setRetailerFormData({
      ...retailerFormData,
      [e.target.name]: e.target.value,
    });
  };

  const handleOrderSubmit = async (e) => {
    e.preventDefault();

    try {
        const response = await axios.post('https://nosht.in/backend/api/contact/order', {
            name: orderFormData.name,
            email: orderFormData.email,
            phone: orderFormData.phone,
            message: orderFormData.message
        });
        setFormSuccess(response.data.message);
        setOrderFormData({
            name: '',
            email: '',
            phone: '',
            message: ''
        });
    } catch (error) {
        setFormError('An error occurred while sending your message.');
    }
};

  const handleRetailerSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post('https://nosht.in/backend/api/contact/retailer', retailerFormData);
      setFormSuccess(response.data.message);
      setRetailerFormData({
        retailer_name: '',
        contact_person: '',
        email: '',
        phone: '',
        message: '',
      });
    } catch (error) {
      setFormError('An error occurred while sending your message.');
    }
  };

  return (
    <div className="container-fluid contact-us-container">
      <div className=" d-flex justify-content-flex-start align-items-lg-start"
       >
        <div className="card p-5" style={{ maxWidth: '600px', width: '100%', borderRadius: '10px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', backgroundColor: 'rgba(255, 255, 255, 0.9)' }}>
          <h2 className="text-center mb-4">Contact Us</h2>

          <div className="form-toggle d-flex justify-content-center mb-4">
            <button
              className={`btn ${isOrderFormVisible ? 'btn-dark' : 'btn-outline-dark'} mx-2`}
              onClick={() => setIsOrderFormVisible(true)}
            >
              For Orders
            </button>
            <button
              className={`btn ${!isOrderFormVisible ? 'btn-dark' : 'btn-outline-dark'} mx-2`}
              onClick={() => setIsOrderFormVisible(false)}
            >
              For Retailers
            </button>
          </div>

          {isOrderFormVisible ? (
            <div>
              <h4 className="mb-3">For Orders:</h4>
              <p>Interested in trying NOSHT makhanas? Place your order today!</p>
              <form className="mb-4" onSubmit={handleOrderSubmit}>
                <div className="row mb-3">
                  <div className="col">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Name"
                      name="name"
                      value={orderFormData.name}
                      onChange={handleOrderChange}
                      required
                    />
                  </div>
                  <div className="col">
                    <input
                      type="email"
                      className="form-control"
                      placeholder="Email"
                      name="email"
                      value={orderFormData.email}
                      onChange={handleOrderChange}
                      required
                    />
                  </div>
                </div>
                <div className="mb-3">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Phone Number"
                    name="phone"
                    value={orderFormData.phone}
                    onChange={handleOrderChange}
                    required
                  />
                </div>
                <div className="mb-3">
                  <textarea
                    className="form-control"
                    rows="3"
                    placeholder="Message/Order Details"
                    name="message"
                    value={orderFormData.message}
                    onChange={handleOrderChange}
                    required
                  ></textarea>
                </div>
                <button type="submit" className="btn btn-dark w-100">Submit</button>
              </form>
            </div>
          ) : (
            <div>
              <h4 className="mb-3">For Retailers:</h4>
              <p>Partner with us to bring NOSHT to your shelves. Contact us for wholesale opportunities.</p>
              <form className="mb-4" onSubmit={handleRetailerSubmit}>
                <div className="mb-3">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Retailer Name"
                    name="retailer_name"
                    value={retailerFormData.retailer_name}
                    onChange={handleRetailerChange}
                    required
                  />
                </div>
                <div className="mb-3">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Contact Person"
                    name="contact_person"
                    value={retailerFormData.contact_person}
                    onChange={handleRetailerChange}
                    required
                  />
                </div>
                <div className="mb-3">
                  <input
                    type="email"
                    className="form-control"
                    placeholder="Email"
                    name="email"
                    value={retailerFormData.email}
                    onChange={handleRetailerChange}
                    required
                  />
                </div>
                <div className="mb-3">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Phone Number"
                    name="phone"
                    value={retailerFormData.phone}
                    onChange={handleRetailerChange}
                    required
                  />
                </div>
                <div className="mb-3">
                  <textarea
                    className="form-control"
                    rows="3"
                    placeholder="Message/Inquiry Details"
                    name="message"
                    value={retailerFormData.message}
                    onChange={handleRetailerChange}
                    required
                  ></textarea>
                </div>
                <button type="submit" className="btn btn-dark w-100">Submit</button>
              </form>
            </div>
          )}

          {formSuccess && (
            <div className="alert alert-success mt-4">
              {formSuccess}
            </div>
          )}

          {formError && (
            <div className="alert alert-danger mt-4">
              {formError}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Contactus;

import React from 'react';

function ProductCard({ image, title, description, price, originalPrice, discount, isNew }) {
  return (
    <div className="col-md-4"> {/* Adjusted to fit 3 products per row */}
      <div className="card" style={{ border: 'none', backgroundColor: '#F9F5EA' }}>
        <div style={{ position: 'relative' }}>
          <img src={image} className="card-img-top img-fluid" alt={title} />
          {discount && (
            <span
              style={{
                position: 'absolute',
                top: '10px',
                right: '10px',
                backgroundColor: '#ff6b6b',
                borderRadius: '50%',
                color: '#fff',
                padding: '5px 10px',
                fontSize: '14px'
              }}
            >
              -{discount}%
            </span>
          )}
          {isNew && (
            <span
              style={{
                position: 'absolute',
                top: '10px',
                right: '10px',
                backgroundColor: '#f8c291',
                borderRadius: '50%',
                color: '#fff',
                padding: '5px 10px',
                fontSize: '14px'
              }}
            >
              NEW
            </span>
          )}
        </div>
        <div className="card-body text-center">
          <h5 className="card-title" style={{ fontWeight: 'bold' }}>{title}</h5>
          {/* <p className="card-text">{description}</p> */}
          {/* <p className="card-text">
            <span style={{ fontSize: '20px', fontWeight: 'bold' }}>${price}</span>
            {originalPrice && (
              <span style={{ textDecoration: 'line-through', marginLeft: '10px', color: '#a0a0a0' }}>${originalPrice}</span>
            )}
          </p>
          <a href="#" className="btn btn-outline-dark rounded-pill px-4">
            ADD TO CART <span style={{ fontSize: '20px' }}>+</span>
          </a> */}
        </div>
      </div>
    </div>
  );
}

export default ProductCard;

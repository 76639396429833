import React from 'react';
import './VisionMissionGoals.css'; // Import the CSS file for styling

const VisionMissionGoals = () => {
  return (
    <div className="vision-mission-goals-container">
      <section className="vision-section">
        <h2>Vision</h2>
        <p>To be the leading provider of healthy and tasty snacks that nourish the body and delight the senses.</p>
      </section>

      <section className="mission-section">
        <h2>Mission</h2>
        <ul>
          <li>To offer premium-quality makhanas that are both nutritious and delicious.</li>
          <li>To promote healthy snacking habits by providing alternatives that are good for both people and the planet.</li>
          <li>To empower local farmers and contribute to the sustainable growth of our community.</li>
        </ul>
      </section>

      <section className="goals-section">
        <h2>Goals</h2>
        <ul>
          <li>Expand the reach of NOSHT makhanas across India and globally.</li>
          <li>Launch new flavors and innovative products that meet customer demands.</li>
          <li>Establish long-term partnerships with retailers and distributors.</li>
        </ul>
      </section>
    </div>
  );
}

export default VisionMissionGoals;
